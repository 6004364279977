import styled from 'styled-components';

import { Box, getRadii, getSpace, Spacer, Typography } from '@aircall/tractor-v2';
import { ReactComponent as ActionErrorTtl } from '@assets/images/action-result/action_error_ttl.svg';
import { ReactComponent as ActionErrorUnknown } from '@assets/images/action-result/action_error_unknown.svg';
import { ReactComponent as SetNewPasswordSucced } from '@assets/images/action-result/set_new_password_succeed.svg';
import { ReactComponent as UpdateEmailSucceed } from '@assets/images/action-result/update_email_succeed.svg';
import { NotFoundErrorScreen } from '@components/NotFoundErrorScreen/NotFoundErrorScreen';
import { FAILED_ACTION, SUCCEED_ACTION } from '@constants/critical-action.constants';
import { LOGIN_ROUTE } from '@constants/routes.constants';
import { ILLUSTRATION_BACKGROUND_COLORS } from '@constants/style.constants';
import { useQueryParams } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

const LayoutWrapper = styled(Spacer)`
  width: calc(100vw - (${getSpace('l')} * 2));
  height: 100vh;
  margin: 0 ${getSpace('l')};
`;

const ImageWrapper = styled(Box)`
  display: flex;
  width: 280px;
  height: 160px;
  align-items: center;
  justify-content: center;
  border-radius: ${getRadii('default')};
`;

const TextWrapper = styled(Spacer)`
  max-width: 450px;
  text-align: center;
`;

const BoldText = styled.div`
  font-weight: 500;
`;

export default function ActionResult() {
  const { t, i18n } = useTranslation();
  const { searchParams } = useQueryParams();

  const action = searchParams.get('action');
  const error = searchParams.get('error');
  const email = searchParams.get('email');

  if (!error && !action) {
    return <Navigate to={LOGIN_ROUTE} />;
  }

  const result = error ? 'error' : 'success';
  const type = error || action!;

  let image;
  let color;
  switch (type) {
    case FAILED_ACTION.TTL:
      image = <ActionErrorTtl />;
      color = ILLUSTRATION_BACKGROUND_COLORS.LIGHT_YELLOW;
      break;
    case FAILED_ACTION.UNKNOWN:
      image = <ActionErrorUnknown />;
      color = ILLUSTRATION_BACKGROUND_COLORS.LIGHT_RED;
      break;
    case SUCCEED_ACTION.NEW_PASSWORD:
      image = <SetNewPasswordSucced />;
      color = ILLUSTRATION_BACKGROUND_COLORS.GREEN;
      break;
    default:
      image = <UpdateEmailSucceed />;
      color = ILLUSTRATION_BACKGROUND_COLORS.LIGHT_GREEN;
      break;
  }

  if (
    !i18n.exists(`action_result.${result}.${type}.title`) ||
    !i18n.exists(`action_result.${result}.${type}.body`)
  ) {
    return <NotFoundErrorScreen />;
  }

  return (
    <LayoutWrapper
      data-test='action-result-page'
      direction='vertical'
      justifyContent='center'
      alignItems='center'
      space='l'
    >
      <ImageWrapper backgroundColor={color}>{image}</ImageWrapper>
      <TextWrapper direction='vertical' space='xxs'>
        <Typography variant='headingBoldXS'>
          {t(`action_result.${result}.${type}.title`)}
        </Typography>
        <Typography variant='bodyRegularM' whiteSpace='pre-line'>
          {t(`action_result.${result}.${type}.body`)}
          {email && <BoldText>{email}</BoldText>}
        </Typography>
      </TextWrapper>
    </LayoutWrapper>
  );
}
